<template>
    <card icon="map-marker-alt"
          :headline="$t('visitors.topCities.headline')"
          :description="$t('visitors.topCities.description')">
        <top-table :limit="limit"
                       :items="cities"
                       :title="$t('visitors.topCities.city')"
                       :visits="$t('visitors.topPages.visits')"
        >
            <template #title="{item, block}">
                <div class="title animate" :class="[ block ]">
                    {{item.city}}
                </div>
            </template>
            <template #visits="{item}">
                {{item.sessions | numberFormat}}
            </template>
        </top-table>
    </card>
</template>

<script>
  import TopTable from '@/app/website/components/TopTable'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      limit: {
        type: Number,
        default: 7
      }
    },

    data () {
      return {
        maxPages: 3
      }
    },

    computed: {
      ...mapGetters('website', {
        analyticsCities: 'getCities'
      }),

      hasLoaded () {
        return this.analyticsCities !== null
      },

      total () {
        return this.cities.length
      },

      cities () {
        if (!this.analyticsCities) return []
        return this.analyticsCities.rows.slice(0, this.limit * this.maxPages)
      },
    },

    components: {
      TopTable
    }
  }
</script>
